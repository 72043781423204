import { Link } from "gatsby";
import React from "react";
import { Container, Row, Col, Nav, Image } from "react-bootstrap";
import { P } from "../../components/Typography/Typography";
import logo from "../../images/logo.svg";
import linkedin from "../../images/sf-linkedin.svg";
import facebook from "../../images/sf-facebook.svg";
import instagram from "../../images/sf-instagram.svg";
import youtube from "../../images/sf-youtube.svg";

const Footer = ({ handleModal }) => (
  <Container as="footer" className="pt-5" fluid>
    <Container>
      <Row>
        <Col>
          <Image src={logo} className="my-4" alt="Softbinator Foundation" />
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <P className="small">
            Legal name: Asociația Softbinator, registration number 35706026
          </P>

          <P className="small">Bucharest, Nicolae Filipescu 39-41, 3 floor</P>
          <P className="small">
            Tel: +40727857328.
            <br />
            E-mail: contact@softbinator.ro
          </P>
          <P className="mt-5 small">
            <strong>
              2020 Softbinator Foundation. All Rights Reserved.{" "}
              <Link to="/privacy-policy" href="/privacy-policy">
                Privacy Policy
              </Link>
            </strong>
          </P>
        </Col>
        <Col md={5}>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Nav.Link className="pt-0" disabled>
              Navigation
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              News
            </Nav.Link>
            <Nav.Link as={Link} to="/media#video">
              Video
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              Careers
            </Nav.Link>
            <Nav.Link onClick={handleModal}>Donate</Nav.Link>
          </Nav>
        </Col>
      </Row>
      <Nav defaultActiveKey="/home" className="sf-follow-us">
        <Nav.Link className="pt-0 mb-2" disabled>
          Follow us
        </Nav.Link>
        <Nav.Link href="https://www.linkedin.com/company/softbinator/">
          <Image src={linkedin} alt="Linkedin" />
        </Nav.Link>
        <Nav.Link href="https://www.facebook.com/softbinator/">
          <Image src={facebook} alt="Facebook" />
        </Nav.Link>
        <Nav.Link href="https://www.instagram.com/softbinator_foundation/">
          <Image src={instagram} alt="Instagram" />
        </Nav.Link>
        <Nav.Link href="https://www.youtube.com/user/softbinator">
          <Image src={youtube} alt="Youtube" />
        </Nav.Link>
      </Nav>
    </Container>
  </Container>
);

export default Footer;
